<script>
import AppNameTooltip from '@/components/AppNameTooltip'

export default {
  name: 'UserAccessPermissions',
  components: {
    AppNameTooltip
  },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      applications: [],
      groups: [],
      options: [],
      ignoredApps: []
    }
  },
  computed: {
    filteredApplications() {
      const callback = (application) => {
        if (this.ignoredApps.includes('ephemeral') && application.env_type === 'ephemeral') {
          return false
        }
        if (this.ignoredApps.includes('staging') && application.env_type === 'staging') {
          return false
        }
        return !(
          this.ignoredApps.includes('production') &&
          (application.env_type === 'production' || application.env_type === null)
        )
      }
      return this.applications.filter(callback)
    },
    formattedPermissions() {
      return this.groups.map((group) => {
        const groupPermissions = this.filteredApplications.reduce((permissions, { name }) => {
          permissions[name] = group.application_names.includes(name)
          return permissions
        }, {})
        return {
          groupName: group.label_or_name,
          ...groupPermissions
        }
      })
    },
    fields() {
      const appNames = this.filteredApplications.map((app) => ({
        key: app.name,
        label: app.name,
        env_type: app.env_type,
        thClass: [
          'user-access-permissions__table__header',
          'border-left-0',
          'border-bottom-1',
          'border-top-0',
          'border-right-0'
        ],
        tdClass: ['border-top-0', 'border-left-0', 'user-access-permissions__td']
      }))
      return [
        {
          key: 'groupName',
          label: '',
          isRowHeader: true,
          stickyColumn: true,
          thStyle: {
            minWidth: '8em'
          },
          thClass: [
            'user-access-permissions__table__header',
            'border-left-0',
            'border-bottom-1',
            'border-top-0',
            'border-right-0'
          ],
          tdClass: ['border-top-0', 'border-left-0', 'user-access-permissions__td']
        },
        ...appNames
      ]
    }
  },
  async created() {
    this.busy = true
    this.applications = await this.api.getOauthApplications()
    this.groups = await this.api.getListGroupsUrl(this.user.id)
    this.options = [
      {
        text: 'Ignore ephemeral applications',
        value: 'ephemeral',
        disabled: !this.applications.some((app) => app.env_type === 'ephemeral') || this.groups.length === 0
      },
      {
        text: 'Ignore staging applications',
        value: 'staging',
        disabled: !this.applications.some((app) => app.env_type === 'staging') || this.groups.length === 0
      },
      {
        text: 'Ignore production applications',
        value: 'production',
        disabled: this.groups.length === 0
      }
    ]
    this.busy = false
  }
}
</script>

<template>
  <div>
    <h3>Permissions</h3>
    <p class="text-muted mb-2">
      Being part of certain groups gives this user access by default to the applications listed in the table below. To
      override these permissions, please use
      <a href="#/access/blocked-applications">the blocked application</a> page.
    </p>

    <div class="card border-secondary small mt-4 mb-4">
      <div class="card-header bg-secondary text-white">Table options:</div>
      <b-form-checkbox-group v-model="ignoredApps" switches :options="options" class="p-2"></b-form-checkbox-group>
    </div>

    <template v-if="groups.length === 0">
      <h6 class="mb-4">This user isn't part of any group.</h6>
    </template>

    <template v-else-if="filteredApplications.length === 0">
      <h6 class="mb-4">No application match your filters.</h6>
    </template>

    <b-table
      v-else
      bordered
      responsive
      no-border-collapse
      striped
      class="user-access-permissions"
      table-class="user-access-permissions__table"
      thead-class="user-access-permissions__table__thead"
      :items="formattedPermissions"
      :fields="fields"
      :busy="busy"
    >
      <template #head()="data">
        <div v-if="data.label" class="user-access-permissions__table__header__text-container">
          <span
            :id="data.label + '-' + data.index"
            class="user-access-permissions__table__header__text-container__label"
          >
            {{ data.label }}
          </span>
          <app-name-tooltip
            :target="data.label + '-' + data.index"
            :label="data.label"
            :env-type="data.field.env_type"
          ></app-name-tooltip>
        </div>
      </template>
      <template #cell()="data">
        <span v-if="data.value === true" class="user-access-permissions__table__cell text-success">&#x2713;</span>
        <span v-else-if="data.value === false" class="user-access-permissions__table__cell text-danger">&#x2717;</span>
        <span v-else>{{ data.value }}</span>
        <span class="sr-only">
          {{ data.value }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<style lang="scss">
.user-access-permissions {
  height: 40rem;

  &__table {
    width: fit-content !important;

    &__thead {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: white;
    }

    &__header {
      white-space: nowrap;
      height: 180px;

      &__text-container {
        transform: translate(25px, 0px) rotate(315deg);
        width: 2.5rem;
        height: 30px;
        line-height: 30px;

        &__label::after {
          position: absolute;
          left: 5px;
          content: '';
          display: block;
          height: 10px;
          border-bottom: 1px solid #dedede;
          width: 13rem;
        }

        &__label {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 12rem;
          display: inline-block;
        }
      }
    }

    &__cell {
      display: block;
      text-align: center;
    }
  }
}
</style>
