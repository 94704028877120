<script>
import { cloneDeep } from 'lodash'

import XemxForm from '@/components/XemxForm'

export default {
  name: 'UserAccessGroups',
  components: { XemxForm },
  inject: ['api'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      groups: [],
      form: cloneDeep(this.user)
    }
  },
  computed: {
    formattedGroups() {
      return this.groups.map((group) => {
        group.nameAndDescription = `
          <strong>${group.label_or_name}</strong>
          <p class="small m-0 text-muted">${group.description}</p>
        `
        return group
      })
    },
    root() {
      return `/users/${this.user.id}`
    },
    updateGroupsUrl() {
      return `${this.root}/update_groups`
    },
    selectedGroups: {
      get() {
        return this.form.groups.map((group) => group.id)
      },
      set(ids) {
        this.form.groups = ids.map((id) => ({ id }))
      }
    }
  },
  async created() {
    this.busy = true
    this.groups = await this.api.getGroups()
    this.busy = false
  }
}
</script>

<template>
  <div class="user-access-groups">
    <h3>Groups</h3>
    <div class="row">
      <xemx-form class="col-12 col-lg-6 mb-2" method="POST" :action="updateGroupsUrl">
        <p class="text-muted">
          Adding a user to a group will grant them access to all the applications each group is associated to. To
          prevent a user from accessing specific applications, please change their
          <a href="#/access/blocked-applications">blocked applications list</a>.
        </p>
        <b-form-group>
          <b-overlay :show="busy" rounded class="user-access-groups__checkbox-group-overlay">
            <b-form-checkbox-group
              v-model="selectedGroups"
              aria-label="Selected groups"
              class="card p-2 mb-2"
              name="groups[]"
              stacked
              html-field="nameAndDescription"
              value-field="id"
              :options="formattedGroups"
            />
          </b-overlay>
          <b-button type="submit" variant="primary"> Update </b-button>
        </b-form-group>
      </xemx-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-access-groups {
  &__checkbox-group-overlay .card {
    min-height: 5rem;
  }
}
</style>
