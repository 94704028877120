import axios from 'axios'

const Method = Object.freeze({
  POST: 'POST',
  PUT: 'PUT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  GET: 'GET'
})
class Api {
  constructor(axios = null) {
    this._axios = axios
  }

  onboardingCheckPgpKey(pgpInput) {
    return this.sendAction('/onboarding/check-pgp-key', {
      method: Method.POST,
      data: {
        pgp_key: pgpInput
      }
    })
  }

  onboardingQrCodeDetails() {
    return this.sendAction('/onboarding/qr-code', { method: Method.GET })
  }

  onboardingPgpKey() {
    return this.sendAction('/onboarding/pgp-key', { method: Method.GET })
  }

  getGroupList() {
    return this.sendAction('/groups/list', { method: Method.GET })
  }

  sendCertificateOnce(userId) {
    return this.sendAction(`/users/${userId}/certificate/send_once`, { method: Method.POST })
  }

  getWebAuthnCredentialList(userId) {
    return this.sendAction(`/users/${userId}/webauthn_credentials/list`, {
      method: Method.GET
    })
  }

  postWebAuthnCredentials(userId) {
    return this.sendAction(`/users/${userId}/webauthn_credentials`, {
      method: Method.POST
    })
  }

  signIn(params) {
    return this.sendAction('sign_in', { method: Method.POST, params })
  }

  getSSHKeys(userId) {
    return this.sendAction(`/users/${userId}/ssh_keys`, { method: Method.GET })
  }

  deleteSSHKey(userId, keyId) {
    return this.sendAction(`/users/${userId}/ssh_keys/${keyId}`, {
      method: Method.DELETE
    })
  }

  getOauthApplications() {
    return this.sendAction('/oauth/applications.json', { method: Method.GET })
  }

  getListGroupsUrl(userId) {
    return this.sendAction(`/users/${userId}/list_groups`, {
      method: Method.GET
    })
  }

  getGroups() {
    return this.sendAction('/groups.json', { method: Method.GET })
  }

  generateMagicLink(userId) {
    const validateStatus = (status) => status === 200
    return this.sendAction(`${userId}/generate_magic_link`, {
      method: Method.GET,
      validateStatus
    })
  }

  getActivityLog(userId) {
    return this.sendAction(`/users/${userId}/activity_log`, {
      method: Method.GET
    })
  }

  getLogInHistory(userId) {
    return this.sendAction(`/users/${userId}/log_in_history`, {
      method: Method.GET
    })
  }

  getSignInUrls(userId) {
    return this.sendAction(`/users/${userId}/sign_ins.json`, {
      method: Method.GET
    })
  }

  updatePosixStatus(userId, action) {
    return this.sendAction(`/users/${userId}/ldap/update_posix_status`, {
      method: Method.POST,
      data: {
        posix_action: action
      }
    })
  }

  updateUserEntryGroup(userId, toAdd, toRemove) {
    return this.sendAction(`/users/${userId}/update_user_entry_groups`, {
      method: Method.POST,
      data: {
        to_add: toAdd,
        to_remove: toRemove
      }
    })
  }

  updateLDAPUnixGroups(userId, toAdd, toRemove) {
    return this.sendAction(`/users/${userId}/ldap/update_ldap_unix_groups`, {
      method: Method.POST,
      data: {
        to_add: toAdd,
        to_remove: toRemove
      }
    })
  }

  getSecurityKeyOptions() {
    return this.sendAction('/users/sign_in/challenge/webauthn/options', {
      method: Method.GET
    })
  }

  updateVpnAccess(userId, action) {
    return this.sendAction(`/users/${userId}/ldap/update_vpn_access`, {
      method: Method.POST,
      data: {
        vpn_action: action
      }
    })
  }

  getUser(userId) {
    return this.sendAction(`/users/${userId}.json`, { method: Method.GET })
  }

  deleteUser(userId) {
    return this.sendAction(`/users/${userId}`, { method: Method.DELETE })
  }

  getCountries() {
    return this.sendAction('/api/v1/countries.json', { method: Method.GET })
  }

  getPermissions(userId) {
    return this.sendAction(`/api/v1/policies/user/${userId}.json`, {
      method: Method.GET
    })
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]')?.content
  }

  withCredentials(config) {
    return {
      ...config,
      headers: { ...config.headers, 'X-CSRF-Token': this.getCsrfToken() },
      withCredentials: true
    }
  }

  async sendAction(url, config = {}) {
    const r = await this._axios?.request({
      url,
      ...this.withCredentials(config)
    })
    return r ? r.data : null
  }
}

export const api = new Api(axios)
